import qs from "query-string";
import { DbEventHost, DbRsvp } from "..";

import { WEB_HOST } from "../constants";
import { UnsubscribeParams } from "../emails/mjml-emails";
import { UrlKind } from "../schema/DbSchema";
import { DbEvent } from "../schema/EventSchema";

// We need to know what kind of URL page we're going to render
// in [...url] to know which loading state to show:
// calendar, event, user or community.
// eg. ?k=c means the URL is for calendar
export const ShortKindParamKey = "k";

const UrlKindToShortKindParamValue: Record<UrlKind, string> = {
  [UrlKind.Calendar]: "c",
  [UrlKind.Event]: "e",
  [UrlKind.User]: "u",
};

export const ShortKindParamToUrlKind: Record<string, UrlKind | undefined> = {};
for (const [key, value] of Object.entries(UrlKindToShortKindParamValue)) {
  ShortKindParamToUrlKind[value] = key as UrlKind;
}

/** Helper to create { k: c | e | ...} query object based on UrlKind */
export const createUrlKindQuery = (kind: UrlKind) => {
  return {
    [ShortKindParamKey]: UrlKindToShortKindParamValue[kind],
  };
};

export namespace EventUrl {
  export type EventPublicPathParams = {
    proxy_key?: DbRsvp["proxy_key"] | null;
    action?: string;
    query?: Record<string, string | number | undefined>;
    event: Pick<DbEvent, "api_id"> & Partial<Pick<DbEvent, "url">>;
    permanent?: boolean;
  };

  export const publicPath = ({
    proxy_key,
    action,
    permanent,
    query: q,
    event,
  }: EventPublicPathParams): string => {
    let path: string;

    if (permanent || !event.url) {
      path = `/e/${event.api_id}`;
    } else {
      path = `/${event.url}`;
    }

    const query = qs.stringify({
      ...q,
      pk: proxy_key || undefined,
      action,
    });
    if (query) {
      path += `?${query}`;
    }

    return path;
  };

  export const publicUrl = ({
    base_url = WEB_HOST,
    ...params
  }: EventPublicPathParams & { base_url?: string }) => {
    return `${base_url}${publicPath(params)}`;
  };

  /**
   * Create a short link that will redirect to the event page.
   */
  export const authRedirectPath = (
    params:
      | { proxy_key: DbRsvp["proxy_key"] }
      | { host_secret_key: DbEventHost["secret_key"] },
  ) => {
    const key =
      "proxy_key" in params ? params.proxy_key : params.host_secret_key;
    return `/e/r/${key}`;
  };

  export const authRedirectUrl = (
    params: Parameters<typeof authRedirectPath>[0],
  ) => WEB_HOST + authRedirectPath(params);

  export const manageUrl = ({
    event,
    base_url = WEB_HOST,
    tab,
    is_new,
    is_cloned,
  }: {
    // We pass in event rather than `event_api_id` since in the future we may
    // want to add the team_api_id to the URL
    event: Pick<DbEvent, "api_id">;
    base_url?: string;
    tab?: string; // TODO: type this
    is_new?: boolean;
    is_cloned?: boolean;
  }) => {
    let path = `/event/manage/${event.api_id}`;

    if (tab) {
      if (tab.startsWith("/")) {
        tab = tab.slice(1);
      }

      path += `/${tab}`;
    }
    const query = qs.stringify({
      new: is_new || undefined,
      clone: is_cloned || undefined,
    });
    if (query) {
      path += `?${query}`;
    }

    return (base_url ?? "") + path;
  };

  export const managePath = (
    params: Omit<Parameters<typeof manageUrl>[0], "base_url">,
  ) => {
    return manageUrl({ ...params, base_url: "" });
  };
}

export enum SurveyRating {
  Great = 5,
  Good = 4,
  Meh = 3,
  Bad = 2,
  Terrible = 1,
}

// See https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
export const URL_REGEX =
  /(http(s)?:\/\/.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

// Modified from https://stackoverflow.com/questions/3717115/regular-expression-for-youtube-links
export const YOUTUBE_REGEX =
  /(?:https?:\/\/)?(?:www\.)?youtu(?:\.be\/|be.com\/\S*(?:watch|embed)(?:(?:(?=\/[^=&\s\?]+(?!\S))\/)|(?:\S*v=|v\/)))([^&\s\?]+)/;

export const youtubeVideoIdFromUrl = (
  url: string | null | undefined,
): string | null => {
  if (!url) {
    return null;
  }

  const match = url.match(YOUTUBE_REGEX);
  if (!match || match.length < 2) {
    return null;
  }
  return match[1];
};

export const getJoinEventUrl = ({ proxyKey }: { proxyKey: string }) => {
  return `${WEB_HOST}/join/${proxyKey}`;
};

export const getHostEventInviteResponse = ({
  eventHostSecret,
  action,
  baseUrl = WEB_HOST,
}: {
  eventHostSecret: string;
  action: "accept" | "decline";
  baseUrl?: string;
}): string => {
  return `${baseUrl}/invite/host-event?${qs.stringify({
    action: action,
    host_secret: eventHostSecret,
  })}`;
};

/**
 * Static folder containing cover images that we use in events.
 */
const STATIC_ROOT = "https://cdn.lu.ma/event-defaults/";

const RANDOM_PATTERN_TYPES = [
  "diamonds",
  "dots",
  "metaballs",
  "rain",
  "squares",
  "stairs",
  "waves",
];

export const RANDOM_PATTERN_URLS = RANDOM_PATTERN_TYPES.map(
  (patternType) =>
    `https://images.lumacdn.com/calendar-defaults/patterns/${patternType}-100.png`,
);

export const getRandomPatternImageUrl = (randomNum?: number): string => {
  randomNum = randomNum || Math.random();
  return RANDOM_PATTERN_URLS[
    Math.floor(randomNum * RANDOM_PATTERN_URLS.length)
  ];
};

export enum CoverImageGroup {
  Pick = "u",
  Gradient = "b",
  Standard = "standard",
  Retro = "retro",
}

export const getCoverImageUrl = ({
  group,
  idx,
}: {
  group: CoverImageGroup;
  idx: number;
}): string => {
  return STATIC_ROOT + `${group}${idx}.jpg`;
};

const DEFAULT_COVER_IMAGE_URLS = [
  "https://cdn.lu.ma/event-defaults/standard1.jpg",
  "https://cdn.lu.ma/event-defaults/standard2.jpg",
  "https://cdn.lu.ma/event-defaults/standard3.jpg",
  "https://cdn.lu.ma/event-defaults/standard4.jpg",
  "https://cdn.lu.ma/event-defaults/standard5.jpg",
  "https://cdn.lu.ma/event-defaults/standard6.jpg",
  "https://cdn.lu.ma/event-defaults/retro1.jpg",
  "https://cdn.lu.ma/event-defaults/retro2.jpg",
  "https://cdn.lu.ma/event-defaults/retro3.jpg",
  "https://cdn.lu.ma/event-defaults/retro4.jpg",
  "https://cdn.lu.ma/event-defaults/retro5.jpg",
  "https://cdn.lu.ma/event-defaults/retro6.jpg",
];

export const getRandomDefaultEventCoverImageUrl = (
  randomNum?: number,
): string => {
  randomNum = Math.max(0, Math.min(1, randomNum || Math.random()));
  return DEFAULT_COVER_IMAGE_URLS[
    Math.floor(randomNum * DEFAULT_COVER_IMAGE_URLS.length)
  ];
};

type KeysOfUnion<T> = T extends T ? keyof T : never;

/**
 * Returns an unsubscribe page link and includes key information that
 * lets user change notification preferences without logging in.
 */
export const getUnsubscribeUrl = (params: UnsubscribeParams): string | null => {
  if (params == null) {
    return null;
  }

  const get = (key: KeysOfUnion<UnsubscribeParams>) => {
    return params && key in params ? (params as any)[key] : undefined;
  };

  const qParams: UnsubscribeUrlParams = {
    calendar_api_id: get("calendar_api_id"),
    calendar_member_secret_key: get("calendar_member_secret_key"),
    ehsk: get("event_host_secret_key"),
    host_api_id: get("host_api_id"),
    pk: get("rsvp_proxy_key"),
    usk: get("user_secret_key"),
  };

  // The ts-ignores are a little lazy but we do this because params is a
  // union and would take some work to get type safety here
  return `${WEB_HOST}/unsubscribe?${qs.stringify(qParams)}`;
};

export type UnsubscribeUrlParams = Partial<{
  calendar_api_id: string;
  calendar_member_secret_key: string;
  event_invite_secret_key: string;
  ehsk: string;
  host_api_id: string;
  msk: string;
  pk: string;
  usk: string;
}>;

// Like Python range()
// https://stackoverflow.com/questions/3895478/does-javascript-have-a-method-like-range-to-generate-a-range-within-the-supp
export const range = (i: number): number[] => {
  return Array.from(Array(i).keys());
};

/**
 * Removes specific element from the array returning the same array.
 * Mutates the input array.
 *
 * @param array Array from which to remove the element
 * @param index Index of the element to remove
 * @returns Mutated array with the element removed
 */
export const removeAt = <T>(array: T[], index: number): T[] => {
  array.splice(index, 1);
  return array;
};

/**
 * Returns copy of the array with leading elements,
 * returning truthy for predicate, function removed.
 */
export function dropWhile<T>(
  _array: Array<T>,
  predicate: (element: T) => boolean,
) {
  const array = [..._array];

  while (array.length > 0 && predicate(array[0])) {
    array.shift();
  }

  return array;
}

/**
 * Returns copy of the array with trailing elements,
 * returning truthy for predicate, function removed.
 */
export function dropRightWhile<T>(
  _array: Array<T>,
  predicate: (element: T) => boolean,
) {
  const array = [..._array];

  while (array.length > 0 && predicate(array[array.length - 1])) {
    array.pop();
  }

  return array;
}

export function sample<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

/**
 * Insert an element between all existing elements of an array.
 */
export function interleave<T>(arr: T[], inserted: T): T[] {
  return arr.flatMap((element) => [element, inserted]).slice(0, -1);
}

import { Currency } from "..";
import { DbCalendar } from "./CalendarSchema";
import { DbUser } from "./UserSchema";
import { BaseTable } from "./schema-utils";

export enum DbTable {
  AiImage = "ai_image",
  AnalyticsEvent = "analytics_event",
  ApiKey = "api_key",
  Calendar = "calendar",
  CalendarAdmin = "calendar_admin",
  CalendarEvent = "calendar_event",
  CalendarMember = "calendar_member",
  CalendarPost = "calendar_post",
  CdnFile = "cdn_file",
  Charge = "charge",
  Contact = "contact",
  ChatConversation = "chat_conversation",
  ChatMessage = "chat_message",
  ChatParticipant = "chat_participant",
  Comment = "comment",
  Coupon = "coupon",
  DeviceToken = "device_token",
  DiscoverEvent = "discover_event",
  DiscoverPlace = "discover_place",
  DiscoverPost = "discover_post",
  DiscoverSubscriber = "discover_subscriber",
  EmailDraft = "email_draft",
  EmailIssue = "email_issue",
  EmailMessage = "email_message",
  Event = "event",
  EventEmail = "event_email",
  EventHost = "event_host",
  EventInvite = "event_invite",
  EventPost = "event_post",
  EventTicketType = "event_ticket_type",
  GalleryImage = "gallery_image",
  GlowChatConversation = "glow_chat_conversation",
  GlowChatMessage = "glow_chat_message",
  GlowChatParticipant = "glow_chat_participant",
  HelpPage = "help_page",
  IcsAuthorization = "ics_authorization",
  LumaPlusSubscription = "luma_plus_subscription",
  LumaPointAdjustment = "luma_point_adjustment",
  LumaPointUsed = "luma_point_used",
  Member = "member",
  Notification = "notification",
  PageReport = "page_report",
  PaymentStarted = "payment_started",
  PhoneNumberBlacklist = "phone_number_blacklist",
  PostmarkEmail = "postmark_email",
  ProfileBlock = "profile_block",
  ProxyClick = "proxy_click",
  Reaction = "reaction",
  Rsvp = "rsvp",
  RsvpPasskitToken = "rsvp_passkit_token",
  StripeAccount = "stripe_account",
  StripeInvoice = "stripe_invoice",
  StripeOnrampSession = "stripe_onramp_session",
  SurveyResponse = "survey_response",
  Tag = "tag",
  TagApplied = "tag_applied",
  Team = "team",
  TeamMember = "team_member",
  Tip = "tip",
  TwilioText = "twilio_text",
  TwoFactorAuthMethod = "two_factor_auth_method",
  Url = "url",
  User = "user",
  UserActivity = "user_activity",
  UserMeta = "user_meta",
  VerificationRequest = "verification_request",
  ZoomWebhookUpdate = "zoom_webhook_update",
}

export enum ScrapedDbTable {
  ScrapedEvent = "scraped_event",
  ScrapedHost = "scraped_host",
  ScrapedHostEmail = "scraped_host_email",
  WebsiteToEmails = "website_to_emails",
}

export type DbUrl = {
  url: string;
  kind: UrlKind;
};

export enum UrlKind {
  Event = "event",
  Calendar = "calendar",
  User = "user",
}

export type DbTip = {
  api_id: string;

  tipper_email: string;
  tipper_api_id: string;
  event_api_id: string | null;
  recipient_api_id: string;

  member_api_id: string;

  stripe_amount_cents: number;
  stripe_payment_intent_id: string;
  stripe_account_id: string | null;
  stripe_currency: Currency | null;

  stripe_connect_customer_id: string | null;
  stripe_platform_customer_id: string | null;
  stripe_charge_id: string | null;

  usd_cents: number | null;

  message: string | null;
  block_info: {
    title: string | null;
    description: string | null;
    block_api_id: string;
  } | null;

  created_at: string;
  updated_at: string;
};

export type DbApiKey = {
  api_key: string;

  user_api_id: DbUser["api_id"];
  calendar_api_id: DbCalendar["api_id"] | null;
} & BaseTable<"api_key">;

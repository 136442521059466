import axios, { AxiosError, AxiosResponseHeaders } from "axios";
import qs from "query-string";
import {
  GlowGetApi,
  GlowPostApi,
  LUMA_HEADER_CLIENT_TYPE,
  LUMA_HEADER_CLIENT_VERSION,
  LumaClientType,
} from "@luma-team/shared";

type Headers = Record<string, string | number | boolean>;
type Fetcher = "axios" | "fetch";
type ZmClientOptions = {
  withCredentials?: boolean;
  fetcher: Fetcher;
  clientType: LumaClientType;
  getCustomHeaders?: GetHeaders | null;
  clientVersion: string;
  onResponse?: <Response>(response: Response) => unknown;
};

export type GlowApiClient = {
  get: <
    Path extends keyof GlowGetApi,
    Request extends GlowGetApi[Path]["request"],
    Response extends GlowGetApi[Path]["response"]
  >(
    path: Path,
    params?: Request,
    customHeaders?: Headers
  ) => Promise<Response>;
  post: <
    Path extends keyof GlowPostApi,
    Request extends GlowPostApi[Path]["request"],
    Response extends GlowPostApi[Path]["response"]
  >(
    path: Path,
    data?: Request,
    customHeaders?: Headers
  ) => Promise<Response>;
};

type GetHeaders = () => Headers | null;

export class GlowClientConstructor implements GlowApiClient {
  private readonly baseUrl: string;
  private readonly withCredentials: boolean;
  private readonly fetcher: Fetcher;
  private readonly clientType: LumaClientType;
  private readonly getCustomHeaders: GetHeaders | null;
  private readonly clientVersion: string | null;
  private readonly onResponse:
    | (<Response>(response: Response) => unknown)
    | null;

  constructor(
    baseUrl: string,
    options: Pick<ZmClientOptions, "clientType"> & Partial<ZmClientOptions>
  ) {
    this.baseUrl = baseUrl;
    this.withCredentials = options?.withCredentials ?? true;
    this.fetcher = options?.fetcher ?? "axios";
    this.clientType = options.clientType;
    this.getCustomHeaders = options.getCustomHeaders ?? null;
    this.clientVersion = options.clientVersion ?? null;
    this.onResponse = options.onResponse ?? null;
  }

  private getHeaders = async (): Promise<Headers> => {
    const customHeaders = this.getCustomHeaders?.() ?? {};
    const headers: Headers = {
      ...customHeaders,
      [LUMA_HEADER_CLIENT_TYPE]: this.clientType,
    };
    if (this.clientVersion) {
      headers[LUMA_HEADER_CLIENT_VERSION] = this.clientVersion;
    }

    return headers;
  };

  public get = async <
    Path extends keyof GlowGetApi,
    Request extends GlowGetApi[Path]["request"],
    Response extends GlowGetApi[Path]["response"]
  >(
    path: Path,
    params?: Request,
    customHeaders?: Headers
  ): Promise<Response> => {
    return (await this.request("get", path, params, customHeaders)) as Response;
  };

  public post = async <
    Path extends keyof GlowPostApi,
    Request extends GlowPostApi[Path]["request"],
    Response extends GlowPostApi[Path]["response"]
  >(
    path: Path,
    data?: Request,
    customHeaders?: Headers
  ): Promise<Response> => {
    return (await this.request("post", path, data, customHeaders)) as Response;
  };

  private request = async (
    requestType: "get" | "post",
    path: string,
    data?: any,
    customHeaders?: Headers
  ) => {
    const authHeaders = await this.getHeaders();
    let headers = Object.assign(authHeaders, customHeaders || {});
    if (requestType === "post") {
      headers = {
        ...headers,
        "Content-Type": "application/json",
      };
    }

    const url = `${this.baseUrl}${path}`;

    if (this.fetcher === "fetch") {
      let fetchUrl = url;
      if (requestType === "get" && data) {
        fetchUrl += `?${qs.stringify(data)}`;
      }

      const config: { body?: string; method: string; headers: Headers } = {
        method: requestType.toUpperCase(),
        headers,
      };
      if (requestType === "post") {
        config.body = JSON.stringify(data);
      }

      // Unfortunately I need to ignore this to get it working in the Node.js context where
      // fetch is not defined.
      // @ts-ignore
      const resp = await fetch(fetchUrl, config);

      if (resp.status >= 400) {
        // We try to throw an Axios error since our front-end code
        // is set up to deal with axios errors.
        let data;

        try {
          data = await resp.json();
        } catch {
          data = {};
        }

        const config = {};
        throw new AxiosError(
          data.message || "Unexpected server error",
          undefined,
          config,
          null,
          {
            data,
            status: resp.status,
            statusText: resp.statusText,
            headers: resp.headers as unknown as AxiosResponseHeaders,
            config,
          }
        );
      }

      const json = await resp.json();
      this.onResponse?.(json);
      return json;
    }

    let func;
    if (requestType === "get") {
      func = axios.get(url, {
        withCredentials: this.withCredentials,
        params: data,
        headers,
      });
    } else {
      func = axios.post(url, data, {
        withCredentials: this.withCredentials,
        headers,
      });
    }

    const { data: axiosData } = await func;
    this.onResponse?.(axiosData);
    return axiosData;
  };
}

import { GlowGetApi } from "@luma-team/shared";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import { ZmClient } from "@utils/ZmClient";

/**
 * Wraps useSwr so that we can use ZmClient
 *
 * Makes a new request whenever path or JSON.stringify(args) change
 */
export const useZmClient = <
  Path extends keyof GlowGetApi,
  Request extends GlowGetApi[Path]["request"],
  Response extends GlowGetApi[Path]["response"],
  SWRConfig extends SWRConfiguration<Response>
>({
  path,
  pause,
  args,
  swrConfig,
  additionalKey,
}: {
  path: Path;
  pause?: boolean;
  args?: Request;
  swrConfig?: SWRConfig;
  additionalKey?: string;
}): {
  // We can be confident that data will be nonnull even if the request fails,
  // if we defined fallbackData in the config.
  data: SWRConfig extends { fallbackData: Response }
    ? Response
    : Response | undefined;
  error: any;
  mutate: SWRResponse<Response, never>["mutate"];
} => {
  const userCacheKeyComponent = "anonymous";
  const swrKey = [userCacheKeyComponent, path, JSON.stringify(args)];

  if (additionalKey) {
    swrKey.push(additionalKey);
  }

  const { data, error, mutate } = useSWR(
    pause ? null : swrKey,
    async () => {
      return await ZmClient.get<Path, Request, Response>(path, args);
    },
    swrConfig
  );

  return { data: data!, error, mutate };
};

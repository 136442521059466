import { Solana } from "@luma-team/shared";
import { createUseAppContext } from "@utils/context";
import { useRouter } from "next/router";
import React, { useState } from "react";

type NetworkContextType = { network: Solana.Network };
const NetworkContext = React.createContext<NetworkContextType | null>(null);

export const NetworkProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();

  const { network: networkParam } = router.query;
  const [network] = useState(() =>
    networkParam && typeof networkParam === "string"
      ? (networkParam as Solana.Network)
      : Solana.Network.Mainnet
  );

  return (
    <NetworkContext.Provider value={{ network }}>
      {children}
    </NetworkContext.Provider>
  );
};

export const useNetworkContext = createUseAppContext(NetworkContext);
